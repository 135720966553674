var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"tgvbLsBFIRuAi8O8IfTv3"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import { version } from './package.json';
const isProd = process.env.NEXT_PUBLIC_NODE_ENV === 'production';

const GOOGLE_PAGESPEED_USER_AGENT = 'moto g power';

function isItBotCheck(userAgent) {
	let isItBot = false;
	const botRegex =
		/lighthouse|googlebot|gtmetrix|HeadlessChrome|yandexbot|bingbot|ahrefs|spider/gi;
	if (typeof userAgent !== 'undefined') {
		isItBot =
			!!userAgent?.match?.(botRegex) ||
			!!userAgent?.match?.(new RegExp(GOOGLE_PAGESPEED_USER_AGENT, 'gi'));
	}
	return isItBot;
}

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	enabled: isProd,
	dsn:
		SENTRY_DSN || 'https://92d4e7e03e7e49d9867f4bd148920236@sentry.trbna.com/3',
	// We recommend adjusting this value in production, or using tracesSampler
	// for finer control
	ignoreErrors: [
		'AbortError',
		'Failed to read the "buffered" property from "SourceBuffer"',
		'NS_ERROR',
		'NS_ERROR_UNEXPECTED',
		'snowplow',
		'Failed to fetch',
		'Failed to execute "observe"',
		'The index is not in the allowed range',
		'Invariant: attempted to hard navigate',
		'IntersectionObserver',
		'ResizeObserver',
	],
	tracesSampler: samplingContext => {
		// Examine provided context data (including parent decision, if any) along
		// with anything in the global namespace to compute the sample rate or
		// sampling decision for this transaction

		const userAgent = samplingContext?.request?.headers?.['user-agent'];

		if (isItBotCheck(userAgent)) {
			// These are not important - take a small sample
			return 0.5;
		} else {
			// Default sample rate
			return 0.5;
		}
	},
	profilesSampleRate: 1.0, // Profiling sample rate is relative to tracesSampleRate
	integrations: [
		Sentry.browserTracingIntegration({
			enableInp: true,
		}),
	],
	release: 'int-tribuna-frontend@' + `v${version}`,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 0.5,
});
