import { ApolloQueryResult, FetchResult } from '@apollo/client';
import {
	Comment,
	CommentsQueries,
	PaginationByPage,
	PaginationInfo,
	PictureType,
	PicturesMutations,
	User,
	UserQueries,
	News,
	NewsQueries,
	Language,
	CommentParentObject,
} from '@ui-kit/main-api-types';

import { useUserStore } from './index';

import { client } from '@apollo-client';
import { client as clientv2 } from '@apollo-client-v2';
import { InputPingDevice } from '@generated-graphql';
import crossFetch from '@utils/fetch';

import mutations from './mutations';
import queries from './queries';

export const pingDevice = async (deviceId: string): Promise<void> => {
	const device: InputPingDevice = {
		screen: typeof window !== 'undefined' ? window.innerWidth.toString() : '',
		platform: 'web',
		build: '1',
		userAgent:
			typeof window !== 'undefined' ? window?.navigator?.userAgent : '',
		timezoneOffset: new Date().getTimezoneOffset() / 60,
	};
	await client.mutate({
		mutation: mutations.pingDeviceId,
		variables: device,
		context: {
			headers: {
				'x-device-id': deviceId,
			},
		},
	});
};

export const banUser = async (userId: string): Promise<void> => {
	const { authToken } = useUserStore.getState().user;
	await clientv2.mutate({
		mutation: mutations.banUser,
		variables: {
			userId,
		},
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
	});
};

export const shadowBanUser = async (userId: string): Promise<void> => {
	const { authToken } = useUserStore.getState().user;
	await client.mutate({
		mutation: mutations.shadowBanUser,
		variables: {
			userId,
		},
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
	});
};

type UserCommentsInput = {
	id: string;
	page?: number;
	pageSize?: number;
	errorPolicy?: string;
};

export const getUserComments = async ({
	id,
	page,
	pageSize,
	errorPolicy = 'all',
}: UserCommentsInput): Promise<{
	userCommentsList: Comment[];
	userCommentsPaginationInfo: PaginationInfo;
}> => {
	const userComments: ApolloQueryResult<{
		commentsQueries: CommentsQueries;
	}> = await clientv2.query({
		query: queries.getUserComments,
		variables: {
			id,
			page: page || 1,
			pageSize: pageSize || 10,
		},
		errorPolicy,
	});

	const userCommentsData = userComments?.data?.commentsQueries?.ByUser;
	const userCommentsList = userCommentsData?.items || [];

	const userCommentsPaginationInfo =
		userCommentsData?.paginationInfo as PaginationByPage;

	return {
		userCommentsList,
		userCommentsPaginationInfo,
	};
};

export const getUserCommentsAvailability = async ({
	id,
}: {
	id: string;
}): Promise<Comment[]> => {
	const userComments: ApolloQueryResult<{
		commentsQueries: CommentsQueries;
	}> = await clientv2.query({
		query: queries.getUserCommentsAvailability,
		variables: {
			id,
		},
		errorPolicy: 'all',
	});

	return userComments?.data?.commentsQueries?.ByUser?.items || [];
};

export const getUserCommentsContentAuthor = async ({
	id,
	page,
	pageSize,
	errorPolicy = 'all',
}: UserCommentsInput): Promise<{
	userCommentsContentAuthorList: Map<string, CommentParentObject>;
}> => {
	const userComments: ApolloQueryResult<{
		commentsQueries: CommentsQueries;
	}> = await clientv2.query({
		query: queries.getUserCommentsContentAuthor,
		variables: {
			id,
			page: page || 1,
			pageSize: pageSize || 10,
		},
		errorPolicy,
	});

	const userCommentsList =
		userComments?.data?.commentsQueries?.ByUser?.items || [];

	return {
		userCommentsContentAuthorList: new Map(
			userCommentsList?.map(comment => [comment?.id, comment?.parentObject]),
		),
	};
};

export const getUploadUrl = (
	token: string,
	data: FormData,
	pictureType: PictureType = PictureType.Content,
) => {
	return clientv2
		.mutate({
			mutation: mutations.getUploadUrl,
			variables: {
				type: pictureType,
			},
			context: {
				headers: {
					'x-auth-token': token,
				},
			},
		})
		.then((res: FetchResult<{ picturesMutations: PicturesMutations }>) => {
			const { uploadURL } = res.data.picturesMutations.uploadByFile;
			return crossFetch(uploadURL, {
				method: 'POST',
				body: data,
				contentType: 'multipart/form-data',
			})
				.then((res: { data: { url: string } }) => {
					const url = res?.data?.url;
					return url || Promise.reject();
				})
				.catch(error => {
					return Promise.reject(error);
				});
		});
};

export const userProfileUpdate = async ({
	bio,
	nickname,
	countryCode,
	city,
	avatarUrl,
}): Promise<
	FetchResult<{
		usersMutations: { updateProfile: User };
	}>
> => {
	const { authToken } = useUserStore.getState().user;
	const userFetchRes = await clientv2.mutate({
		mutation: mutations.userProfileUpdate,
		variables: {
			bio,
			nickname,
			countryCode,
			city,
			avatarUrl,
		},
		errorPolicy: 'all',
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
	});
	return userFetchRes;
};

export const getUser = async ({ userId, locale }) => {
	const userQuery: ApolloQueryResult<{ userQueries: UserQueries }> =
		await clientv2.query({
			query: queries.getUser,
			variables: {
				id: userId,
			},
			context: {
				headers: {
					'X-Language': locale?.toLowerCase(),
					'x-tribuna-app': 'football',
				},
			},
		});
	return userQuery?.data?.userQueries?.get;
};

type UserNewsInput = {
	authorId: string;
	language: Language;
	page?: number;
	pageSize?: number;
	errorPolicy?: string;
};

export const getUserNews = async ({
	authorId,
	language,
	page,
	pageSize,
	errorPolicy = 'all',
}: UserNewsInput): Promise<{
	userNewsList: News[];
	newsLastPage: number;
}> => {
	const userNews: ApolloQueryResult<{
		newsQueries: NewsQueries;
	}> = await clientv2.query({
		query: queries.getUserNews,
		variables: {
			author: authorId,
			lang: language,
			page,
			pageSize,
		},
		errorPolicy,
	});

	const userNewsData = userNews?.data?.newsQueries?.feed;

	const userNewsList = userNewsData?.items || [];

	const newsLastPage = (userNewsData?.paginationInfo as PaginationByPage)
		?.lastPage;

	return {
		userNewsList,
		newsLastPage,
	};
};

export const getUserNewsAvailability = async ({
	id,
}: {
	id: string;
}): Promise<News[]> => {
	const userNews: ApolloQueryResult<{
		newsQueries: NewsQueries;
	}> = await clientv2.query({
		query: queries.getUserNewsAvailability,
		variables: {
			author: id,
		},
		errorPolicy: 'all',
	});

	return userNews?.data?.newsQueries?.feed?.items || [];
};
